import React, { memo, useEffect } from "react"
import PropTypes from "prop-types"
import { NOOP } from "../../../constants/noop"

import CrossIcon2 from "../../../assets/icons/CrossIcon2"

import styles from "./index.module.scss"
import FaqSlider from "../../../components/Sliders/FaqSlider"

const FaqPopup = ({ data, defaultIndex = 0, onClose = NOOP }) => {
  useEffect(() => {
    const closeHandler = (e) => {
      if (e.key === "Escape") onClose()
    }

    window.addEventListener("keydown", closeHandler)
    return () => {
      window.addEventListener("keydown", closeHandler)
    }
  }, [])

  return (
    <div className={styles.box}>
      <button className={styles.close} type='button' onClick={onClose}>
        <CrossIcon2 fill='#ffffff' />
      </button>
      <div className={styles.box__inner}>
        <FaqSlider data={data} defaultIndex={defaultIndex} />
      </div>
    </div>
  )
}

FaqPopup.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      image: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string
    })
  ),
  defaultIndex: PropTypes.number,
  onClose: PropTypes.func
}

export default memo(FaqPopup)
