/* eslint-disable consistent-return */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getFaqReq } from "../../api/faq"
// import { breadcrumbsApi } from "./fakeApi"

const initialState = {
  faqList: [],
  selectedFaqIndex: -1,
  isListLoading: true
}

export const getFaq = createAsyncThunk("product/getFaq", async (id, { rejectWithValue }) => {
  try {
    const response = await getFaqReq(id)
    if (response.status === 200) {
      if (response.data) {
        return response.data
      }
      return {}
    }
  } catch (error) {
    return rejectWithValue()
  }
})

/* eslint-disable no-param-reassign */
export const faqSlice = createSlice({
  name: "faq",
  initialState,
  reducers: {
    selectFaqItem(state, action) {
      return {
        ...state,
        selectedFaqIndex: action.payload
      }
    },
    disposeFaqPage() {
      return {
        ...initialState
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFaq.pending, (state) => {
        state.isListLoading = true
      })
      .addCase(getFaq.fulfilled, (state, action) => {
        return {
          ...state,
          faqList: action.payload.data,
          isProductLoading: false,
          isProductLoaded: true
        }
      })
      .addCase(getFaq.rejected, (state) => {
        state.isListLoading = false
      })
  }
})

// Product detail data
export const selectFaqData = (state) => state.faq.faqList
export const selectFaqToPreviewIndex = (state) => state.faq.selectedFaqIndex
// Loaders
export const selectIsFaqListLoading = (state) => state.product.isListLoading

export const { selectFaqItem, disposeFaqPage } = faqSlice.actions

export default faqSlice.reducer
