import React, { memo, useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { Link } from "react-router-dom"
import { useTranslate } from "../../../utils/hooks/useTranslate"
import FallbackImg from "../../FallbackImg"
import { CATEGORY_CARD_FALLBACK_IMAGE_URL } from "../../../constants/fallback-image"

import { NOOP } from "../../../constants/noop"

import styles from "./index.module.scss"

const FaqCard = ({ id, image, title, description, onClick = NOOP }) => {
  const { t } = useTranslate()
  const [isImageError, setImageError] = useState(false)

  return (
    <div
      role='button'
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          onClick()
        }
      }}
      tabIndex={0}
      onClick={() => {
        onClick()
      }}
      className={cx(styles.box, {
        [styles["image-error"]]: isImageError
      })}
    >
      <div className={styles.box__inner}>
        <div className={styles.image}>
          <FallbackImg alt={title} fallback={CATEGORY_CARD_FALLBACK_IMAGE_URL} setIsFallback={setImageError} />
        </div>
        <div className={styles.content}>
          <div
            className={styles.title}
            dangerouslySetInnerHTML={{
              __html: title
            }}
          />
          <button
            onClick={() => {
              onClick()
            }}
            type='button'
            className={styles.link}
          >
            {t["button-view"]}
          </button>
        </div>
      </div>
    </div>
  )
}

FaqCard.propTypes = {
  id: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  onClick: PropTypes.func
}

export default FaqCard
