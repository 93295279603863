/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-console */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-param-reassign */
import React, { memo, useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { Navigation, Autoplay } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"
import cx from "classnames"
import ChewronLeftIcon from "../../../assets/icons/ChewronLeftIcon"
import Container from "../../../modules/Container"
import { NOOP } from "../../../constants/noop"

import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/scrollbar"
import "swiper/css/autoplay"

import styles from "./index.module.scss"

const FaqSlider = ({ data = [], defaultIndex = 0 }) => {
  const [loaded, setLoaded] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(defaultIndex)

  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)

  useEffect(() => {
    setCurrentIndex(defaultIndex)
  }, [defaultIndex])

  useEffect(() => {
    setLoaded(true)
  }, [])

  return (
    <div className={cx(styles.box, {})}>
      <div className={styles.slider}>
        {loaded && !!data.length && (
          <Swiper
            modules={[Navigation, Autoplay]}
            spaceBetween={20}
            slidesPerView='auto'
            slidesOffsetBefore={0}
            initialSlide={defaultIndex}
            height='auto'
            // autoHeight='true'
            slidesOffsetAfter={0}
            pagination={{ clickable: true }}
            onSlideChange={NOOP}
            onSwiper={NOOP}
            watchSlidesProgress
            watchOverflow
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current
            }}
            onBeforeInit={(swiper) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current
              swiper.params.navigation.nextEl = navigationNextRef.current
              swiper.navigation.init()
              swiper.navigation.update()
            }}
            onAfterInit={(swiper) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current
              swiper.params.navigation.nextEl = navigationNextRef.current
              swiper.navigation.init()
              swiper.navigation.update()
            }}
            onResize={(swiper) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current
              swiper.params.navigation.nextEl = navigationNextRef.current
              swiper.navigation.init()
              swiper.navigation.update()
            }}
            onRealIndexChange={(swiper) => {
              setCurrentIndex(swiper.realIndex)
            }}
          >
            {data.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className={styles.slide}>
                    <div className={styles.image}>
                      <img src={item.image} alt={item.title} />
                    </div>
                    <div className={styles.text}>
                      <span
                        className={styles.title}
                        dangerouslySetInnerHTML={{
                          __html: item.title
                        }}
                      />
                      <span
                        className={styles.description}
                        dangerouslySetInnerHTML={{
                          __html: item.description
                        }}
                      />
                    </div>
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        )}
      </div>
      <Container className={styles.controls}>
        {!!data.length && (
          <>
            <button type='button' className={styles.button_prev} ref={navigationPrevRef}>
              <ChewronLeftIcon color='#333333' />
            </button>
            <span>
              {currentIndex + 1} / {data.length}
            </span>
            <button type='button' className={styles.button_next} ref={navigationNextRef}>
              <ChewronLeftIcon color='#333333' />
            </button>
          </>
        )}
      </Container>
    </div>
  )
}

FaqSlider.propTypes = {
  data: PropTypes.any,
  defaultIndex: PropTypes.number
}

export default memo(FaqSlider)
