import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"
import { Link, useLocation } from "react-router-dom"
import cx from "classnames"
import { useDispatch, useSelector } from "react-redux"
import Container from "../Container/index"
import Search from "../Search"
import CartBadge from "../../components/CartBadge"
import Burger from "../../components/Burger"
import AccountButton from "../../components/AccountButton"
import Switch, { SwitchVariantEnum } from "../../components/Switch"
import { useTranslate } from "../../utils/hooks/useTranslate"
import { useElementFixed } from "../../utils/hooks/useElementFixed"

import { selectProductsBrands, toggleProductsFiltersBurger } from "../../pages/Products/products.controller"

import { getTranslations, setLocale } from "../../store/slice/translations"
import { logOut } from "../../store/slice/auth"
import { selectAccountPopover, selectBurgerMenu, toggleAccountPopover, toggleBurgerMenu } from "../../store/slice/page"
import { useWindowSize } from "../../utils/hooks/useWindowSize"
import { TRANSLATE_CONSTANT_KEYS } from "../../constants/translate-keys"
import { selectCartProductCount } from "../../pages/Cart/cart.controller"
import { getFiltersFromParams } from "../../pages/Products/utils/parse-params"

import HeartIcon from "../../assets/icons/HeartIcon"
import HomeIcon from "../../assets/icons/HomeIcon"

import styles from "./index.module.scss"

const HeaderNavEnum = {
  HOME: "/",
  PRODUCTS: "/products",
  CART: "/cart",
  FAVORITES: "/profile/favorites",
  ORDERS: "/profile/orders",
  PROFILE: "/profile",
  FAQ: "/faq"
}

const DisabledHeaderTransitionRoutes = [HeaderNavEnum.ORDERS]

const Header = ({ logo, logoLink, isAuth }) => {
  const { t } = useTranslate()
  const headerRef = useRef()
  const dispatch = useDispatch()
  const location = useLocation()
  const countItems = useSelector(selectCartProductCount)
  const allBrands = useSelector(selectProductsBrands)
  const { isOpen } = useSelector(selectAccountPopover)
  const { isOpen: isBurgerOpen } = useSelector(selectBurgerMenu)
  const { brandID } = getFiltersFromParams()

  const { locale } = useTranslate()
  const { isMobile } = useWindowSize()

  const [isHeaderFixed, setIsHeaderFixed] = useState(false)
  const [isHeaderFixedVisible, setIsHeaderFixedVisible] = useState(false)

  const brandColor = useMemo(() => {
    return allBrands.find((item) => item.id === brandID) || allBrands?.[0] || {}
  }, [allBrands, isAuth, location])

  const isHeaderTransitionDisabled = useMemo(
    () => DisabledHeaderTransitionRoutes.some((item) => item === location.pathname),
    [location]
  )

  useElementFixed(headerRef, setIsHeaderFixed, setIsHeaderFixedVisible)

  return (
    <header
      className={cx(styles.box, {
        [styles.fixed]: isHeaderFixed,
        [styles.visible]: isHeaderFixedVisible,
        [styles["fixed-disable-transition"]]: isHeaderTransitionDisabled
      })}
      style={{
        "--header-border-bottom": isAuth ? brandColor?.front_color : "transparent"
      }}
      ref={headerRef}
    >
      <Container className={styles.box__inner}>
        <Link to={logoLink} className={styles.logo}>
          <img src={logo} alt='logo' />
        </Link>
        {isAuth && !isMobile && (
          <div className={styles.toolbar}>
            <Search />
            <Link to={HeaderNavEnum.PRODUCTS} className={cx({ [styles.active]: location.pathname === HeaderNavEnum.PRODUCTS })}>
              <HomeIcon />
            </Link>
            <Link to={HeaderNavEnum.CART} className={cx({ [styles.active]: location.pathname === HeaderNavEnum.CART })}>
              <CartBadge count={countItems} />
            </Link>
            <Link to={HeaderNavEnum.FAVORITES} className={cx({ [styles.active]: location.pathname === HeaderNavEnum.FAVORITES })}>
              <HeartIcon />
            </Link>
            <AccountButton
              content={
                <>
                  <Link to='/'>{t["navigation-dashboard"]}</Link>
                  <Link to='/profile/orders'>{t["navigation-orders"]}</Link>
                  <Link to='/profile'>{t["navigation-profile-2"]}</Link>
                  <Link to={HeaderNavEnum.FAQ}>{t["navigation-faq"]}</Link>
                  <Link
                    to='/'
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      dispatch(logOut())
                    }}
                  >
                    {t["navigation-logout"]}
                  </Link>
                </>
              }
              isOpen={isOpen}
              toggleAccountPopover={() => dispatch(toggleAccountPopover())}
            />
          </div>
        )}
        {isAuth && isMobile && (
          <>
            <div className={styles["toolbar-mobile"]}>
              <Link to={HeaderNavEnum.PRODUCTS} className={cx({ [styles.active]: location.pathname === HeaderNavEnum.PRODUCTS })}>
                <HomeIcon />
              </Link>
              <Link to={HeaderNavEnum.CART} className={cx({ [styles.active]: location.pathname === HeaderNavEnum.CART })}>
                <CartBadge count={countItems} />
              </Link>
            </div>
            <Burger
              isOpen={isBurgerOpen}
              onClick={() => {
                dispatch(toggleProductsFiltersBurger(false))
                dispatch(toggleBurgerMenu(!isBurgerOpen))
              }}
            />
          </>
        )}
        {!isAuth && (
          <Switch
            checkedHandleIcon='EN'
            uncheckedHandleIcon='DE'
            checkedIcon='EN'
            uncheckedIcon='DE'
            checked={locale === "en"}
            onChange={(v) => {
              dispatch(setLocale(v ? "en" : "de"))
              dispatch(getTranslations(TRANSLATE_CONSTANT_KEYS))
            }}
            variant={SwitchVariantEnum.SECONDARY}
          />
        )}
      </Container>
    </header>
  )
}

Header.propTypes = {
  logo: PropTypes.string,
  logoLink: PropTypes.string,
  isAuth: PropTypes.bool
}

export default Header
