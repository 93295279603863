import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import cx from "classnames"

import Layout from "../../modules/Layout"
import { useTranslate } from "../../utils/hooks/useTranslate"
import Button, { ButtonVariantEnum } from "../../components/Button"
import Container from "../../modules/Container"
import Spinner from "../../components/Spinner"
import FaqCard from "../../components/Cards/FaqCard"
import FaqPopup from "./FaqPopup"
import {
  disposeFaqPage,
  getFaq,
  selectFaqData,
  selectFaqItem,
  selectFaqToPreviewIndex,
  selectIsFaqListLoading
} from "./faq.controller"

import styles from "./index.module.scss"

const FaqPage = () => {
  const { t } = useTranslate()
  const dispatch = useDispatch()

  const list = useSelector(selectFaqData)
  const selectedIndex = useSelector(selectFaqToPreviewIndex)
  const isListLoading = useSelector(selectIsFaqListLoading)

  useEffect(() => {
    document.title = "FAQ"

    dispatch(getFaq())

    return () => dispatch(disposeFaqPage())
  }, [])

  return (
    <Layout>
      <div
        className={cx(styles.box, {
          [styles.opened]: selectedIndex >= 0
        })}
      >
        <Container className={styles.box__inner}>
          <h1 className={styles.title}>
            <div dangerouslySetInnerHTML={{ __html: t["faq-page-title"] }} />
          </h1>
          {selectedIndex < 0 && (
            <div className={styles.list}>
              {list.map((item, i) => {
                return (
                  <FaqCard
                    image={item.image}
                    description={item.description}
                    title={item.title}
                    onClick={() => {
                      dispatch(selectFaqItem(i))
                    }}
                  />
                )
              })}
            </div>
          )}
          {selectedIndex >= 0 && (
            <FaqPopup defaultIndex={selectedIndex} data={list} onClose={() => dispatch(selectFaqItem(-1))} />
          )}
          {selectedIndex < 0 && isListLoading && <Spinner />}
          <div className={styles.button}>
            <Button href='/products' text={t["button-to-shop"]} variant={ButtonVariantEnum.PRIMARY} />
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default FaqPage
