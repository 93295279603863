import * as React from "react"
import PropTypes from "prop-types"
import { pxToRem } from "../../utils/px-to-rem"

const CrossIcon2 = ({ color, fill, strokeWidth, ...props }) => (
  <svg
    width={`${pxToRem(58)}rem`}
    height={`${pxToRem(58)}rem`}
    viewBox='0 0 58 58'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <line x1='44.0176' y1='15.0264' x2='15.0262' y2='44.0178' stroke={color || "black"} strokeWidth='0.5' />
    <line x1='42.957' y1='44.0166' x2='13.9656' y2='15.0252' stroke={color || "black"} strokeWidth='0.5' />
  </svg>
)

CrossIcon2.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  strokeWidth: PropTypes.number
}

export default CrossIcon2
